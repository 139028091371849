/**
 * @author: laoona
 * @date:  2022-03-03
 * @time: 18:38
 * @contact: laoona.com
 * @description: #
 */

import React from "react"
import styled from "styled-components"

const Styles = styled.span`
  display: inline-block;

  svg {
    vertical-align: top;
  }
`

const Link = <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M4.72199 19.778C5.18587 20.2425 5.73696 20.6108 6.34361 20.8617C6.95026 21.1126 7.60051 21.2412 8.25699 21.24C8.91362 21.2411 9.56401 21.1126 10.1708 20.8617C10.7776 20.6108 11.3289 20.2425 11.793 19.778L14.621 16.949L13.207 15.535L10.379 18.364C9.81546 18.925 9.05266 19.24 8.25749 19.24C7.46232 19.24 6.69952 18.925 6.13599 18.364C5.5745 17.8007 5.2592 17.0378 5.2592 16.2425C5.2592 15.4472 5.5745 14.6843 6.13599 14.121L8.96499 11.293L7.55099 9.87899L4.72199 12.707C3.7858 13.6454 3.26004 14.9169 3.26004 16.2425C3.26004 17.5681 3.7858 18.8395 4.72199 19.778V19.778ZM20.278 11.293C21.2137 10.3543 21.7391 9.08291 21.7391 7.75749C21.7391 6.43207 21.2137 5.16071 20.278 4.22199C19.3395 3.2858 18.0681 2.76004 16.7425 2.76004C15.4169 2.76004 14.1454 3.2858 13.207 4.22199L10.379 7.05099L11.793 8.46499L14.621 5.63599C15.1845 5.07498 15.9473 4.76002 16.7425 4.76002C17.5377 4.76002 18.3005 5.07498 18.864 5.63599C19.4255 6.19926 19.7408 6.96216 19.7408 7.75749C19.7408 8.55282 19.4255 9.31572 18.864 9.87899L16.035 12.707L17.449 14.121L20.278 11.293Z"
    fill="currentColor" />
  <path d="M8.964 16.95L7.549 15.536L16.036 7.05L17.45 8.465L8.964 16.95Z" fill="currentColor" />
</svg>

const Copy = <svg width="1em" height="1em" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2273 1H3.5C2.39543 1 1.5 1.89543 1.5 3V14.1818" stroke="currentColor" strokeWidth="2"/>
    <rect x="6.22727" y="5.72728" width="15.2727" height="15.2727" rx="1" stroke="currentColor" strokeWidth="2"/>
  </svg>
;

export const IconLink = () => {
  return <Styles>
    {Link}
  </Styles>
}

export const IconCopy = () => {
  return <Styles>
    {Copy}
  </Styles>
}
